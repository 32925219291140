import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import Alert from '@mui/material/Alert'
import Timeline from '@mui/lab/Timeline'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import NotesTable from './NotesTable'
import { CalendarHeatMap, SymptomScoresLifetime } from '@components'
import SymptomScoresChart from '@components/SymptomScoresChart'
import { navigate } from 'gatsby'
import { paths } from 'routes'

import MDEditor from '@uiw/react-md-editor'

const PREFIX = 'NoteEdit'

const classes = {
	root: `${PREFIX}-root`,
	navigation: `${PREFIX}-navigation`,
	breadcrumb: `${PREFIX}-breadcrumb`,
	alertSection: `${PREFIX}-alertSection`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	compactSearch: `${PREFIX}-compactSearch`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	link: `${PREFIX}-link`,
	icon: `${PREFIX}-icon`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},

	[`& .${classes.navigation}`]: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},

	[`& .${classes.breadcrumb}`]: {
		textTransform: 'capitalize',
	},

	[`& .${classes.alertSection}`]: {
		marginBottom: theme.spacing(2),
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},

	[`& .${classes.contentWrapper}`]: {
		padding: theme.spacing(2),
	},

	[`& .${classes.link}`]: {
		display: 'flex',
	},

	[`& .${classes.icon}`]: {
		marginRight: theme.spacing(0.5),
		width: 20,
		height: 20,
	},
}))

function handleClick(event) {
	event.preventDefault()
}

const NoteEdit = (props) => {
	const note = props.note.current
	const [editing, setEditing] = useState(!!!note.uuid)
	const [title, setTitle] = useState(note.title ? note.title : `${moment().format('MM-DD')} - Note`)
	const [document, setDocument] = useState(
		note.document
			? note.document
			: `# Example Patient Note

You can create notes easily!  Notes are either private or sharable documents with your patient and care team.  They are an easy way for you to record anything you need after patient visits, when remotely monitor your patient, or to prepare some content for your next visit with the patient.

Insert images and links is a breeze!
[Dieta Health](https://www.dietahealth.com)

![Dieta Logo](https://images.squarespace-cdn.com/content/v1/5c05d69b7106991c243d6ae8/1575336398196-S3LNME712SWVPN0P7NHS/ke17ZwdGBToddI8pDm48kJfu36RSn1-ZnBm70aUlfVdZw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVGEvreEn3kJ9NpScHpcGBUobIQz-CTF0iCEzZUQlLCU92bSd6kfRtgWHgNMDgGnmDY/dancing+poop+gif.gif?format=100w)

List items are helpful too
- First 
- Second
- Third
`
	)

	return (
		<Root className={classes.root}>
			<div className={classes.navigation}>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
					<Link
						color="inherit"
						href={'/notes'}
						onClick={(event) => {
							event.preventDefault()
							props.clearCurrentNote()
							navigate(paths.notes)
						}}
						className={classes.link}
					>
						<Typography className={classes.breadcrumb}>Notes</Typography>
					</Link>
					<Link
						color="inherit"
						onClick={(event) => {
							event.preventDefault()
						}}
						className={classes.link}
					>
						<Typography className={classes.breadcrumb}>{title}</Typography>
					</Link>
				</Breadcrumbs>
			</div>

			<Grid container spacing={2} justifyContent={'flex-start'} alignItems="flex-start">
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
							<Toolbar>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs>
										{editing ? (
											<TextField
												fullWidth
												value={title}
												onChange={(event) => {
													setTitle(event.target.value)
												}}
												placeholder={'Note title'}
												InputProps={{
													disableunderline: 'true',
													className: classes.searchInput,
													inputProps: {
														className: classes.compactSearch,
													},
												}}
											/>
										) : (
											<Typography>{title}</Typography>
										)}
									</Grid>
									{note.uuid ? (
										<>
											<Grid item>
												<Tooltip arrow title="Create" aria-label="delete note">
													<Button
														color="primary"
														onClick={async () => {
															await props.deleteNote(note)
															props.clearCurrentNote()
															navigate(paths.notes)
														}}
													>
														Delete
													</Button>
												</Tooltip>
											</Grid>
											<Grid item>
												{editing ? (
													<Tooltip arrow title="Create" aria-label="save note">
														<Button
															variant="contained"
															color="primary"
															onClick={async () => {
																await props.updateNote({
																	...note,
																	title,
																	document,
																})
																props.clearCurrentNote()
																navigate(paths.notes)
															}}
														>
															Save
														</Button>
													</Tooltip>
												) : (
													<Tooltip arrow title="Create" aria-label="edit note">
														<Button
															variant="contained"
															color="primary"
															onClick={() => {
																setEditing(!editing)
															}}
														>
															Edit
														</Button>
													</Tooltip>
												)}
											</Grid>
										</>
									) : (
										<Grid item>
											<Tooltip arrow title="Create" aria-label="create note">
												<Button
													variant="contained"
													color="primary"
													onClick={async () => {
														await props.addNote({
															...note,
															title,
															document,
														})
														props.clearCurrentNote()
														navigate(paths.notes)
													}}
												>
													Create
												</Button>
											</Tooltip>
										</Grid>
									)}
								</Grid>
							</Toolbar>
						</AppBar>
						<Grid
							container
							wrap={'wrap'}
							alignItems="center"
							justifyContent="center"
							className={classes.contentWrapper}
						>
							<Grid xs={12} item>
								<div className="container">
									{editing ? (
										<MDEditor
											height={500}
											value={document}
											preview={'live'}
											onChange={setDocument}
											previewOptions={{
												transformLinkUri: null,
												skipHtml: true,
											}}
										/>
									) : (
										<MDEditor.Markdown source={document} />
									)}
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Root>
	)
}

NoteEdit.propTypes = {
	location: PropTypes.object,
	note: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
			uuid: PropTypes.string,
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
		lastFetched: PropTypes.number,
	}),
	addNote: PropTypes.func.isRequired,
	updateNote: PropTypes.func.isRequired,
	getNote: PropTypes.func.isRequired,
	deleteNote: PropTypes.func.isRequired,
	clearCurrentNote: PropTypes.func.isRequired,
}

const mapStateToProps = ({ note }) => {
	return {
		note,
	}
}

export default connect(mapStateToProps, actions)(NoteEdit)
