import React, { useEffect, seState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'
import NotesRoot from './NotesRoot'
import NoteEdit from './NoteEdit'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'

import moment from 'moment'

const PREFIX = 'NotesContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.addUser}`]: {
		marginRight: theme.spacing(1),
	},

	[`& .${classes.container}`]: {
		padding: '48px 36px 0',
	},
}))

function NoteContent(props) {
	const pathTail = props.location.pathname
		.split('notes')
		.filter((d) => d.replace(/\//g, ''))
		.pop()
	const [noteUuid, setNoteUuid] = React.useState(pathTail ? pathTail.replace(/\//g, '') : '')
	const isLoading =
		props.note.loading.read ||
		props.note.loading.create ||
		props.note.loading.update ||
		props.note.loading.delete ||
		props.clinician.loading.create ||
		props.clinician.loading.read ||
		props.clinician.loading.update ||
		props.clinician.loading.delete

	useEffect(() => {
		setNoteUuid(
			props.location.pathname
				.split('notes')
				.filter((d) => d.replace(/\//g, ''))
				.pop()
		)
	}, [props.location.pathname])
	return (
		<Root>
			<LinearProgress visible={isLoading} color="primary" />
			{noteUuid ? (
				<NoteEdit location={props.location} isLoading={isLoading} />
			) : (
				<NotesRoot location={props.ocation} isLoading={isLoading} />
			)}
		</Root>
	)
}

NoteContent.propTypes = {
	location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return state
}

export default compose(connect(mapStateToProps, actions), withTranslation())(NoteContent)
