import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Alert from '@mui/material/Alert'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import NotesTable from './NotesTable'
import Content from '@components/Content'

import CircularProgress from '@mui/material/CircularProgress'
import { CalendarHeatMap, SymptomScoresLifetime } from '@components'
import SymptomScoresChart from '@components/SymptomScoresChart'
import { navigate } from 'gatsby'
import { paths } from 'routes'
import { cacheDuration } from '../../../environment'

const PREFIX = 'NotesRoot'

const classes = {
	root: `${PREFIX}-root`,
	alertSection: `${PREFIX}-alertSection`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	compactSearch: `${PREFIX}-compactSearch`,
	tableWrapper: `${PREFIX}-tableWrapper`,
	widgetWrapper: `${PREFIX}-widgetWrapper`,
	contentWrapper: `${PREFIX}-contentWrapper`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.alertSection}`]: {
		marginBottom: theme.spacing(2),
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},

	[`& .${classes.tableWrapper}`]: {
		alignItems: 'flex-start',
		minHeight: 400,
	},

	[`& .${classes.widgetWrapper}`]: {
		padding: theme.spacing(2),
	},

	[`& .${classes.contentWrapper}`]: {
		padding: '48px 36px 48px',
	},
}))

function Row(props) {
	const { row } = props

	return (
		<React.Fragment>
			<TableRow>
				<TableCell align="left" padding="checkbox">
					<span
						style={{
							padding: 5,
							borderRadius: 5,
							backgroundColor: row.role === 'Dietitian' ? 'red' : 'green',
							color: 'white',
						}}
					>
						{row.role}
					</span>
				</TableCell>
				<TableCell align="left">
					<Typography
						variant="caption"
						display="block"
						style={{
							fontSize: 11,
						}}
					>
						{row.name}
					</Typography>
				</TableCell>
				<TableCell align="left" padding="checkbox">
					<Typography
						variant="caption"
						display="block"
						style={{
							fontSize: 11,
						}}
					>
						{row.value}
					</Typography>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string,
		role: PropTypes.string,
		value: PropTypes.string,
	}).isRequired,
}

const NotesRoot = (props) => {
	const [search, setSearch] = useState('')

	const isLoading =
		props.note.loading.read ||
		props.note.loading.create ||
		props.note.loading.update ||
		props.note.loading.delete ||
		props.clinician.loading.create ||
		props.clinician.loading.read ||
		props.clinician.loading.update ||
		props.clinician.loading.delete

	useEffect(() => {
		if (
			!props.note.lastFetched ||
			(props.note.lastFetched + cacheDuration <= new Date().getTime() && !props.note.loading.read)
		) {
			props.getNotes()
			props.getClinicians()
		}
	}, [])

	const notes = props?.note?.data || []
	const clinicians = (props.clinician?.data || []).reduce((acc, clinician) => {
		if (!acc[clinician.id]) {
			acc[clinician.id] = { ...clinician, role: clinician.title }
		}
		return acc
	}, {})
	const providerNotes = notes.reduce((acc, item) => {
		if (!acc[item.clinician_id]) {
			acc[item.clinician_id] = []
		}

		acc[item.clinician_id].push({ ...item, clinician: clinicians[item.clinician_id] })
		return acc
	}, {})

	const flatProviders = Object.keys(providerNotes)

	return (
		<Root className={classes.root}>
			<Grid container spacing={2} alignItems="center" className={classes.alertSection}>
				<Grid item xs={12}>
					<Alert variant="outlined" severity="info">
						Notes are documents you can write for yourself or to share with your patient. They can be shared
						with other providers for the purpose of care collaboration.
					</Alert>
				</Grid>
			</Grid>
			<Grid container spacing={2} justifyContent={'flex-start'} alignItems="flex-start">
				<Grid item xs={9}>
					<Paper className={classes.paper}>
						<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
							<Toolbar>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<SearchIcon className={classes.block} color="inherit" />
									</Grid>
									<Grid item xs>
										<TextField
											fullWidth
											value={search}
											onChange={(event) => {
												setSearch(event.target.value)
											}}
											placeholder={`Search notes`}
											InputProps={{
												disableunderline: 'true',
												className: classes.searchInput,
												inputProps: {
													className: classes.compactSearch,
												},
											}}
										/>
									</Grid>

									<Grid item>
										<Tooltip arrow title="Create" aria-label="create note">
											<Button
												variant="contained"
												color="primary"
												onClick={async () => {
													await props.setCurrentNote()
													navigate(`${paths.notes}/create`)
												}}
											>
												Add New
											</Button>
										</Tooltip>
									</Grid>

									<Grid item>
										<Tooltip arrow title="Reload" aria-label="reload">
											<IconButton
												color="inherit"
												onClick={() => {
													props.getNotes()
													props.getClinicians()
												}}
												size="large"
											>
												<RefreshIcon className={classes.block} color="inherit" />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>

						<Grid
							container
							wrap={'wrap'}
							alignItems="center"
							justifyContent="center"
							className={classes.tableWrapper}
						>
							<Grid xs={12} item>
								{isLoading ? (
									<Grid container justifyContent="center" className={classes.root}>
										<CircularProgress disableShrink={true} />
									</Grid>
								) : props.note?.data?.length ? (
									<NotesTable
										note={props.note.data}
										clinicians={clinicians}
										setCurrentNote={props.setCurrentNote}
										onSetCurrentNote={(note) => {
											navigate(`${paths.notes}/${note.uuid}`)
										}}
									/>
								) : (
									<Content title={'No notes create yet!'} subtitle={'Create your first note above'} />
								)}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper className={classes.paper}>
						<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
							<Toolbar>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<SearchIcon className={classes.block} color="inherit" />
									</Grid>
									<Grid item xs>
										<TextField
											fullWidth
											value={search}
											onChange={(event) => {
												setSearch(event.target.value)
											}}
											placeholder={`Search providers`}
											InputProps={{
												disableunderline: 'true',
												className: classes.searchInput,
												inputProps: {
													className: classes.compactSearch,
												},
											}}
										/>
									</Grid>
								</Grid>
							</Toolbar>
						</AppBar>
						<Grid
							container
							wrap={'wrap'}
							alignItems="center"
							justifyContent="center"
							className={classes.widgetWrapper}
						>
							<Grid xs={12} item>
								<Grid container>
									{isLoading ? (
										<Grid container justifyContent="center" className={classes.root}>
											<CircularProgress disableShrink={true} />
										</Grid>
									) : props.note?.data?.length ? (
										<TableContainer component={Paper}>
											<Table>
												<TableBody>
													{flatProviders.map((providerId) => {
														return (
															<Row
																key={providerId}
																row={{
																	...clinicians[providerId],
																	value: providerNotes[providerId].length,
																}}
															/>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									) : (
										<Grid container justifyContent="center" className={classes.widgetWrapper}>
											<Typography>No Notes created yet</Typography>
										</Grid>
									)}
								</Grid>
								{/* <Typography component="h2" variant="h5" color="textSecondary" align="center">
									No notes have been created for this patient
								</Typography> */}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Root>
	)
}

NotesRoot.propTypes = {
	note: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
			uuid: PropTypes.string,
		}),
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
			create: PropTypes.bool.isRequired,
			update: PropTypes.bool.isRequired,
			delete: PropTypes.bool.isRequired,
		}),
		lastFetched: PropTypes.number,
	}),
	clinician: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
			create: PropTypes.bool.isRequired,
			update: PropTypes.bool.isRequired,
			delete: PropTypes.bool.isRequired,
		}),
	}),
	getNotes: PropTypes.func.isRequired,
	setCurrentNote: PropTypes.func.isRequired,
	getNote: PropTypes.func.isRequired,
	getClinicians: PropTypes.func.isRequired,
}

const mapStateToProps = ({ note, clinician }) => {
	return {
		note,
		clinician,
	}
}

export default connect(mapStateToProps, actions)(NotesRoot)
