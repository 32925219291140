import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { TimeSeconds } from 'utils/helpers'
import moment from 'moment'

const PREFIX = 'NotesTable'

const classes = {
	root: `${PREFIX}-root`,
	container: `${PREFIX}-container`,
	rows: `${PREFIX}-rows`,
	visuallyHidden: `${PREFIX}-visuallyHidden`,
}

const StyledPaper = styled(Paper)({
	[`&.${classes.root}`]: {
		width: '100%',
		overflowX: 'auto',
	},
	[`& .${classes.container}`]: {
		maxHeight: 640,
		minHeight: 400,
		overflowX: 'auto',
	},
	[`& .${classes.rows}`]: {
		flexDirection: 'column',
	},
	[`& .${classes.visuallyHidden}`]: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
})

const columns = [
	{ id: 'title', label: 'Title' },
	{ id: 'name', label: 'Author' },
	{ id: 'role', label: 'Role' },
	{
		id: 'occurred_at',
		label: 'Note Created',
		format: (value) => {
			return moment(value).format('MM/DD/YYYY')
		},
	},
	{
		id: 'edit',
		label: 'View',
		format: (value) => value,
	},
]

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
	const { order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

export default function NotesTable({ note, clinicians, setCurrentNote, onSetCurrentNote }) {
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('occurred_at')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const notes = note

	return (
		<StyledPaper className={classes.root}>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table">
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={notes.length}
					/>
					<TableBody>
						{stableSort(notes, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((note, index) => {
								const clinician = clinicians[note.clinician_id]
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={`${note.id}-${index}`}>
										{columns.map((column) => {
											const value = note[column.id]
											if (column.id === 'edit') {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															<Button
																variant="contained"
																color="primary"
																onClick={() => {
																	setCurrentNote(note)
																	onSetCurrentNote(note)
																}}
															>
																View
															</Button>
														</Grid>
													</TableCell>
												)
											}
											if (value) {
												return (
													<TableCell key={column.id} align={column.align}>
														<Grid container direction="row" alignItems="center">
															{column.format ? column.format(value) : value}
														</Grid>
													</TableCell>
												)
											} else if (clinician) {
												const value = clinician[column.id]
												if (column.id === 'name') {
													return (
														<TableCell key={column.id} align={column.align}>
															<Grid container direction="row" alignItems="center">
																{column.id === 'name' ? (
																	<Avatar
																		src={clinician.picture}
																		style={{
																			marginRight: 10,
																		}}
																		imgProps={{
																			onError: (e) => {
																				console.log(e)
																			},
																		}}
																	></Avatar>
																) : (
																	<></>
																)}
																{column.format ? column.format(value) : value}
															</Grid>
														</TableCell>
													)
												} else {
													return (
														<TableCell key={column.id} align={column.align}>
															<Grid container direction="row" alignItems="center">
																{column.format ? column.format(value) : value}
															</Grid>
														</TableCell>
													)
												}
											}
										})}
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={notes.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</StyledPaper>
	)
}
